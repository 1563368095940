import React, { Component, Fragment } from "react"; //eslint-disable-line
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import { Col, Row, Steps, Spin, Alert, Checkbox } from "antd";
import NewOrderStore from "../../../stores/Order/NewOrderStore";
import Notification from "../../Notification";
import i18n from "../../../core/i18n";

import RenderSummary from "./orderStep2Page/RenderSummary";
import OrderFormCard from "./orderStep2Page/OrderFormCard";
import OrderFormSummaryFooter from "./orderStep2Page/OrderFormSummaryFooter";
import CustomerSummary from "./orderStep2Page/renderSummary/CustomerSummary";
import CarCategoryStore from "../../../stores/CarCategory/CarCategoryStore";

const { Step } = Steps;

class OrderStep2Page extends Component {
    static propTypes = {
        mode: PropTypes.string,
    };

    static defaultProps = {
        mode: null,
    };

    state = {
        viewport: 0,
    };

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        const { body } = document;
        const html = document.documentElement;
        body.scrollTop = 0;
        html.scrollTop = 0;
        this.resize();
    }

    resize() {
        this.setState({ viewport: window.innerWidth });
    }

    render() {
        const { mode, form } = this.props;
        const { router } = form;

        const values = NewOrderStore.formValues;

        const formItemLayout = {
            colon: false,
            labelCol: {
                xs: { span: 24 },
                sm: { span: 10 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 14 },
            },
        };

        let title;
        if (NewOrderStore.editOrder) {
            title = "Editace objednávky";
        } else {
            title = "Nová objednávka";
        }
        return (
            <div className="content__inner order-form__step2">
                <Row gutter={25}>
                    <Col lg={30} md={24}>
                     
                        <div className="order-form__steps-wrapper">
                            <div className="order-form__steps">
                                <Steps progressDot current={NewOrderStore.backRouteStep ? 2 : 1}>
                                    <Step title={i18n.t("Vyplnit formulář")} />
                                    {NewOrderStore.backRouteStep && <Step title={i18n.t("Zpáteční cesta")} />}
                                    <Step title={i18n.t("Souhrn")} />
                                </Steps>
                            </div>
                        </div>
                                   
                        {mode === "excursion" && CarCategoryStore.routeTotalDuration / 60 > 14 && (
                            <Alert message={i18n.t("Trasa je delší než 14 hodin. Doporučujeme snížit dobu pobytu.")} type="info" />
                        )}

                        <div className="order-form order-form__summary">

                            <h4>{i18n.t("Souhrn")} — {i18n.t(title)}</h4>
                            {values.routes.map((route, index) => (
                                <div key={index} /* eslint-disable-line react/no-array-index-key */>
                                    <RenderSummary route={route} index={index} formItemLayout={formItemLayout} values={values} />
                                </div>
                            ))}

                            <div style={{ marginTop: "35px", marginBottom: "35px" }}>
                                <CustomerSummary router={router} values={values} formItemLayout={formItemLayout} />
                            </div>
                            <div className="gdpr-component" style={{ marginTop: "35px", marginBottom: "35px" }}>
                                <OrderFormCard values={values} />
                            </div>

                            <Notification type="new-order" />

                            <OrderFormSummaryFooter />
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default observer(OrderStep2Page);
