import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import { Row, Col, Card, Divider, Popover } from "antd";
import {
  PushpinOutlined,
  HourglassOutlined,
  RetweetOutlined,
  PictureOutlined,
  CheckCircleOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { withRouter } from "next/router";
import { Form } from "@ant-design/compatible";
import NewOrderFormSchema from "../../../forms/Order/NewOrderFormSchema";
import OrderStep1Page from "./OrderStep1Page";
import OrderStep2Page from "./OrderStep2Page";
import OrderTourPage from "../Tours/OrderTourPage";
import NewOrderStore from "../../../stores/Order/NewOrderStore";
import Sidebar from "../../order/Sidebar";
import i18n from "../../../core/i18n";
import NotificationStore from "../../../stores/NotificationStore";
import InvalidAddressModal from "./orderStep1Page/InvalidAddressModal";
import ContactUsModal from "./orderStep1Page/ContactUsModal";
import RouteDuplicateErrorModal from "../../orderList/RouteDuplicateErrorModal";
import { pushRoute } from "../../Router.tsx";

const { Meta } = Card;

/**
 * Custom hook to get the current window width.
 * Returns null during SSR.
 */
function useWindowWidth() {
  const [width, setWidth] = useState(null);
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return width;
}



const OrderPage = (props) => {
  const { form, router } = props;
  const [activeCard, setActiveCard] = useState("order");
  const windowWidth = useWindowWidth();

  useEffect(() => {
    const getActiveCard = (router) => {
      const { pathname } = router;
      let active = "order";
      if (pathname.includes("hour-rental")) {
        active = "hour-rental";
      } else if (pathname.includes("excursion")) {
        active = "excursion";
      } else if (pathname.includes("tours")) {
        active = "tours";
      }
      return active;
    };
    setActiveCard(getActiveCard(router));
    NewOrderStore.mode = getActiveCard(router);
  }, [router]);

  const handleCardClick = (key) => {
    NewOrderStore.reset();
    if (key === "excursion" || key === "hour-rental") {
      NewOrderStore.backRoute = false;
    }
    NewOrderStore.mode = key;
    NotificationStore.reset();
    NewOrderStore.resetForm = true;

    const adjustedKey = key === "order" ? "index" : key;
    const params = { lang: router.query.lang };
    if (adjustedKey !== "index") {
      params.path = adjustedKey;
    }
    pushRoute(router, adjustedKey, params, { shallow: true });
  };

  const renderContent = () => {
    const { step } = NewOrderStore;

    if (activeCard === "tours") {
      return <OrderTourPage />;
    }

    return (
      <div className="content">
        {step === 1 && <OrderStep1Page form={formInstance} mode={activeCard} />}
        {step === 2 && <OrderStep2Page form={formInstance} mode={activeCard} />}
      </div>
    );
  };

  const renderNavigationCards = (activeCard) => {
    // Adjusted texts for titleLong (concise and informative)
    const cards = [
      {
        key: "order",
        title: i18n.t("Transfery"),
        titleLong: i18n.t("Point-to-point transport."),
        icon: <RetweetOutlined style={{ fontSize: "28px", color: "#1890ff" }} />,
      },
      {
        key: "hour-rental",
        title: i18n.t("Hodinový pronájem"),
        titleLong: i18n.t("Hourly rental with driver."),
        icon: <HourglassOutlined style={{ fontSize: "28px", color: "#52c41a" }} />,
      },
      {
        key: "excursion",
        title: i18n.t("Exkurze"),
        titleLong: i18n.t("Multi-stop trips with waiting options."),
        icon: <PushpinOutlined style={{ fontSize: "28px", color: "#faad14" }} />,
      },
      {
        key: "tours",
        title: i18n.t("Zajezdy"),
        titleLong: i18n.t("Shared or private tours and day trips."),
        icon: <PictureOutlined style={{ fontSize: "28px", color: "#eb2f96" }} />,
      },
    ];

    return (
      <Row gutter={[16, 16]}>
        {cards.map((card) => (
          <Col xs={12} sm={12} md={12} lg={6} key={card.key}>
            <Card
              hoverable
              onClick={() => handleCardClick(card.key)}
              className={`order-card ${activeCard === card.key ? "active" : ""}`}
              role="button"
              tabIndex={0}
              onKeyUp={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  handleCardClick(card.key);
                }
              }}
              aria-pressed={activeCard === card.key}
            >
              <div className="order-card-cover">{card.icon}</div>
              <Meta
               class="ant-card-meta-new"
               title={
                <Popover
                  content={
                    card.titleLong ? i18n.t(card.titleLong) : i18n.t(card.title)
                  }
                  placement="bottom"
                >
                  <span>{card.title}</span>
                </Popover>
              }
              className="order-card-meta-title"
              />
            </Card>
          </Col>
        ))}
      </Row>
    );
  };

  // Create the form instance.
  const formInstance = new NewOrderFormSchema(router, form, activeCard);
  
  const { step } = NewOrderStore;

  return (
    <div style={{ background: "#fff", minHeight: "100vh" }}>
      {/* Modals */}
      <RouteDuplicateErrorModal form={formInstance} />
      <InvalidAddressModal />
      <ContactUsModal />

      <Row gutter={[30, 30]} className="whole-form-padding">
        <Col xs={24} sm={24} md={24} lg={18}>
          {step !== 2 && renderNavigationCards(activeCard)}
          <div>{renderContent()}</div>
        </Col>

        {/* Render sidebar column only when windowWidth is known and >= 992px */}
        {windowWidth && windowWidth >= 992 && (
          <Col md={6} lg={6}>
            <div className="order-sidebar">
              <Sidebar />

              {step === 1 && (
                <div className="order-sidebar__box">
                  <div className="order-sidebar__headline">
                    <h3 className="order-sidebar__title">
                      {i18n.t("Free extras")}
                    </h3>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <CheckCircleOutlined style={{ color: "#1890ff", marginInlineEnd: "8px" }} />
                      <a
                        href={i18n.t("free tour - url")}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {i18n.t("Free tour voucher")}
                      </a>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <CheckCircleOutlined style={{ color: "#1890ff", marginInlineEnd: "8px" }} />
                      <a
                        href={i18n.t("guide book - url")}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {i18n.t("Guidebook & map")}
                      </a>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <CheckCircleOutlined style={{ color: "#1890ff", marginInlineEnd: "8px" }} />
                      <span>{i18n.t("Free bottled water")}</span>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <CheckCircleOutlined style={{ color: "#1890ff", marginInlineEnd: "8px" }} />
                      <span>{i18n.t("Wi-fi hotspot")}</span>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <CheckCircleOutlined style={{ color: "#1890ff", marginInlineEnd: "8px" }} />
                      <span>{i18n.t("Quality service")}</span>
                    </div>
                  </div>
                  <p>
                    {i18n.t(
                      "Promo materials including maps, a Prague guide, and a free 4-hour tour voucher are provided directly by our driver."
                    )}
                  </p>
                </div>
              )}

              {step === 2 && (
                <div className="order-sidebar__box">
                  <div className="order-sidebar__headline">
                    <h3 className="order-sidebar__title">
                      {i18n.t("Useful information")}
                    </h3>
                  </div>
                  <h6 className="order-sidebar__subtitle">
                    {i18n.t("Reservation process")}
                  </h6>
                  <p>{i18n.t("Instructions")}</p>
                  <Divider />
                  <h6 className="order-sidebar__subtitle">
                    {i18n.t("Free Cancellation")}
                  </h6>
                  <p>
                    {i18n.t(
                      "Most services can be cancelled anytime free of charge with no deposit required."
                    )}
                  </p>
                </div>
              )}
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};

OrderPage.propTypes = {
  form: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
};

export default Form.create()(withRouter(observer(OrderPage)));
