import React from "react";
import { Popover, Button } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import HelpButton from "../../../../HelpButton";
import i18n from "../../../../../core/i18n";
import NewOrderStore from "../../../../../stores/Order/NewOrderStore";

const Notes = ({ form, routeNumber, formValues }) => {
  const showNotes = NewOrderStore.showNotes;

  const toggleNotesCard = () => {
    NewOrderStore.toggleNotesCard();
  };

  return (
    <div className="order-form-notes FlipDiv">
      <h2 className="order-form-notes-title">
        {i18n.t("Poznámka (speciální požadavky)")}
        <Popover
          overlayClassName="order-form__popover"
          placement="top"
          content={i18n.t("Poznámky k této rezervaci")}
        >
          <HelpButton />
        </Popover>
        <span className="order-form__extras-button" style={{ marginLeft: 10 }}>
          <Button
            type="ghost"
            style={{
              backgroundColor: "#ebe7e7",
              fontSize: 14,
              border: "1px solid rgba(0, 0, 0, 0.25)",
            }}
            onClick={toggleNotesCard}
          >
            {showNotes ? (
              <span>
                <MinusOutlined style={{ marginInlineEnd: 6 }} />
                <span className="extra-label">{i18n.t("Skrýt")}</span>
              </span>
            ) : (
              <span>
                <PlusOutlined style={{ marginInlineEnd: 6 }} />
                <span className="extra-label">{i18n.t("Přidat")}</span>
              </span>
            )}
          </Button>
        </span>
      </h2>

      {showNotes && (
        
            <div className="order-form__notes-content">
            {form.render(`routes[${routeNumber}][noteForDriver]`, {
                initialValue: formValues.routes[routeNumber].noteForDriver || "",
                trigger: "onBlur",
            })}
            </div>
        
        )}
    </div>
  );
};

Notes.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  routeNumber: PropTypes.number.isRequired,
  formValues: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default observer(Notes);
