import React, { Component } from "react";
import { observer } from "mobx-react";
import { Typography, Layout, Row, Col, Card, Space, Divider } from "antd";
import { ClockCircleOutlined, TagOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { withRouter } from "next/router";
import Link from "next/link";
import { dynamicRoutes } from "../../Router.tsx";
import TourListStore from "../../../stores/Tours/TourListStore";
import CurrencyStore from "../../../stores/Currency/CurrencyStore.js";
import getAmountInForeignCurrency from "../../../utils/newOrderStore/getAmountInForeignCurrency.js";
import i18n from "../../../core/i18n";

class TourContent extends Component {
  static propTypes = {
    tours: PropTypes.arrayOf(PropTypes.object).isRequired,
    router: PropTypes.object.isRequired,
  };

  render() {
    const { Title, Text } = Typography;
    const { Content } = Layout;
    const { tours, router } = this.props;
    const tourPath = dynamicRoutes({ lang: router.query.lang }).replace(/\/+$/, "");

    const showForeignCurrency =
      CurrencyStore.selectedCurrency !== CurrencyStore.CZK && CurrencyStore.currencies;

    return (
      <Layout>
        <Content className="tourgrid" style={{ padding: "0px", backgroundColor: "white" }}>
          <Row gutter={16}>
            {tours.map((tour) => (
              <Col
                key={tour.id}
                className="gutter-row"
                xs={24}
                sm={12}
                md={8}
                lg={8}
                xl={8}
                style={{ marginBottom: "15px" }}
              >
                <Link
                  href={{
                    pathname: `/${tourPath}/tours/${tour.slug}`.replace(/\/+/g, "/"),
                  }}
                >
                  <Card
                    hoverable
                    cover={
                      <img
                        alt={tour.name}
                        src={tour.photos ? tour.photos : TourListStore.defaultImagePath}
                      />
                    }
                  >
                    <Title level={5} style={{ marginTop: 5 }}>
                      {tour.name}
                    </Title>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {/* Icon before tour type */}
                      <TagOutlined style={{ marginInlineEnd: 6, fontSize: "14px" }} />
                      <Text type="secondary">{i18n.t(tour.tourType)}</Text>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {/* Icon before tour duration */}
                      <ClockCircleOutlined style={{ marginInlineEnd: 6, fontSize: "14px" }} />
                      <Text type="secondary">{i18n.t(tour.durationTime)}</Text>
                    </div>
                    <Space
                      size={2}
                      style={{ justifyContent: "flex-start", flexWrap: "wrap", marginTop: 10 }}
                    >
                      <Title level={5} style={{ margin: 0, fontSize: "16px" }}>
                        {showForeignCurrency && (
                          <span>
                            {getAmountInForeignCurrency(
                              TourListStore.displayPrice(tour),
                              CurrencyStore.selectedCurrency
                            )}
                            &nbsp;
                          </span>
                        )}
                        {!showForeignCurrency && (
                          <span>
                            {TourListStore.displayPrice(tour)}&nbsp;
                            {CurrencyStore.CZK}&nbsp;
                          </span>
                        )}
                      </Title>
                      <Text style={{ fontSize: "13px" }}>{i18n.t(tour.pricingType)}</Text>
                    </Space>
                  </Card>
                </Link>
              </Col>
            ))}
          </Row>
        </Content>
      </Layout>
    );
  }
}

export default withRouter(observer(TourContent));
