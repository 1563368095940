import React, { Component } from "react";
import { Spin, Switch } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { observer } from "mobx-react";
import NewOrderStore from "../../../../../../stores/Order/NewOrderStore";
import i18n from "../../../../../../core/i18n";
import CarCategoryStore from "../../../../../../stores/CarCategory/CarCategoryStore";
import { CarsListCustomCars } from "./CarsListCustomCars";
import { CarsListTopCars } from "./CarsListTopCars";

class CarsList extends Component {
    componentDidUpdate() {
        if (
            !CarCategoryStore.isLoading &&
            CarCategoryStore.carCategories.length &&
            !NewOrderStore.selectedCars[NewOrderStore.getRouteWay()].length &&
            !NewOrderStore.chooseCustomCar[NewOrderStore.getRouteWay()]
        ) {
            NewOrderStore.selectCar();
        }
        if (!CarCategoryStore.isLoading && NewOrderStore.selectedCars) {
            NewOrderStore.filterExtras();
            NewOrderStore.setIfRouteHasToBePaidInAdvance();
        }
    }

    canShowCarList() {
        return (
            ((NewOrderStore.mode === "excursion" &&
                NewOrderStore.excursionPoints &&
                NewOrderStore.excursionPoints.length > 1 &&
                NewOrderStore.excursionPoints[0].place &&
                NewOrderStore.excursionPoints[0].place.length > 0 &&
                NewOrderStore.excursionPoints[1].place &&
                NewOrderStore.excursionPoints[1].place.length > 0) ||
                (NewOrderStore.start &&
                    NewOrderStore.start !== NewOrderStore.finish &&
                    NewOrderStore.finish)) &&
            ((CarCategoryStore.routeDistance !== -1 && CarCategoryStore.routeDuration !== -1) || 
            NewOrderStore.mode === "hour-rental")
        );
    }

    render() {
        const chooseCustomCar = NewOrderStore.chooseCustomCar[NewOrderStore.getRouteWay()];

        if (!this.canShowCarList()) {
            return <Spin size="large" spinning={CarCategoryStore.isLoading} />;
        }

        return (
            <>
                
                    <h2 className="order-form-vehicles">
                        {chooseCustomCar ? i18n.t("Vybrat vozidla ručně") : i18n.t("Dostupná vozidla")}
                    </h2>
                
                <span className="white-space-nowrap">
                    <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={chooseCustomCar}
                        onChange={() => {
                            NewOrderStore.resetSelectedCars();
                            NewOrderStore.resetSelectedCarsCustom();
                        }}
                    />
                    <span style={{ fontSize: "13px" }}>
                        {chooseCustomCar
                            ? i18n.t("Zobrazit doporučená vozidla")
                            : i18n.t("Vybrat vozidla ručně")}
                    </span>
                </span>

                <div className="small-screen-spacer"></div>

                <Spin size="small" spinning={CarCategoryStore.isLoading}>
                    <div>
                        {chooseCustomCar ? <CarsListCustomCars /> : (
                            <CarsListTopCars
                                selectedCar={NewOrderStore.getSelectedCarId()}
                                onSelect={(car) => NewOrderStore.selectCar(car)}
                            />
                        )}
                    </div>
                </Spin>
            </>
        );
    }
}

export default observer(CarsList);
