import React, { Component } from "react";
import { Button, Row, Col, Card, Form, Divider, Popover, Tag, Select } from "antd";
import { PlusOutlined, EnvironmentOutlined, UndoOutlined, MinusCircleOutlined, SmileOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import NewOrderStore from "../../../../../stores/Order/NewOrderStore";
import FileUploadStore from "../../../../../stores/FileUploadStore";
import i18n from "../../../../../core/i18n";
import RenderStats from "./RenderStats";
import CarCategoryStore from "../../../../../stores/CarCategory/CarCategoryStore";
import AutoCompleteLocation from "../../../../autoCompleteLocation/AutoCompleteLocation";
import HelpButton from "../../../../HelpButton";
import PageActionHeader from "../../../../PageActionHeader";
import LocationStore from "../../../../../stores/Location/LocationStore";

const { Option } = Select;

class RenderExcursion extends Component {
    static propTypes = {
        startLocationOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
        routeNumber: PropTypes.number.isRequired,
    };

    loadLocationInfo(index, place) {
        const locationInfo = LocationStore.locations.find((location) => `${location.name} - ${location.address}` === place);

        if (locationInfo && locationInfo.excursion) {
            if (index !== 0) {
                NewOrderStore.excursionPoints[index].waitingTime = locationInfo.duration;
            }
            NewOrderStore.excursionPoints[index].locations = locationInfo.id;
            NewOrderStore.excursionPoints[index].description = locationInfo.description;

            FileUploadStore.load("location", locationInfo.id, "location");
        } else {
            NewOrderStore.excursionPoints[index].waitingTime = 0;
            NewOrderStore.excursionPoints[index].description = "";
            NewOrderStore.excursionPoints[index].locations = "";
        }
    }

    addNewPoint(index) {
        const finishIndex = NewOrderStore.excursionPoints.length - 1;
        const finish = NewOrderStore.excursionPoints[finishIndex];
        const start = NewOrderStore.excursionPoints[0];
        const { chargeLastExcursionPoint } = CarCategoryStore;

        return (
            <Divider>
                {!chargeLastExcursionPoint && index === NewOrderStore.excursionPoints.length - 1 && (
                    <div style={{ marginBottom: "8px" }}>
                        <Popover overlayClassName="order-form__popover" content={i18n.t("Poslední úsek cesty se neúčtuje")} title={false}>
                            <Tag color="#3d6800">
                                {i18n.t("Zpáteční cesta zdarma")}
                                <SmileOutlined style={{ color: "white", marginLeft: 6 }} />
                            </Tag>
                        </Popover>
                    </div>
                )}
                <Button
                    style={{ margin: 5, border: "1px solid #005093" }}
                    type="text"
                    size="middle"
                    id="add-next-point"
                    onClick={() => {
                        NewOrderStore.addExcursionPoint(index + 1);
                    }}
                >
                    <PlusOutlined /> {i18n.t("Přidat další bod")}
                </Button>
                {index === finishIndex && !(start.place === finish.place) && (
                    <Button
                        style={{ margin: 5, border: "1px solid #005093" }}
                        type="text"
                        onClick={() => {
                            NewOrderStore.addExcursionPoint(index + 1);
                            NewOrderStore.excursionPoints[index + 1].place = start.place;
                            this.loadLocationInfo(index + 1, start.place);
                            CarCategoryStore.loadExcursion(NewOrderStore.excursionPoints, NewOrderStore.passengersCount);
                        }}
                    >
                        <UndoOutlined /> {i18n.t("Chci se vrátit zpět")}
                    </Button>
                )}
            </Divider>
        );
    }

    handleWaitingTimeChange = (index, value) => {
        NewOrderStore.excursionPoints[index].waitingTime = value;
        CarCategoryStore.loadExcursion(NewOrderStore.excursionPoints, NewOrderStore.passengersCount);
    };

    renderWaitingTime(index) {
        const waitingTimeOptions = [{ label: i18n.t("without waiting"), value: 0 }];

        for (let hours = 0; hours <= 23; hours++) {
            for (let minutes = 0; minutes < 60; minutes += 15) {
                const totalMinutes = hours * 60 + minutes;

                // Handle hours based on the number (grammatical correctness for Czech)
                let hoursLabel = "";
                if (hours > 0) {
                    if (hours === 1) {
                        hoursLabel = `${hours} ${i18n.t("hodina")}`; // Singular hour (1 hodina)
                    } else if (hours >= 2 && hours <= 4) {
                        hoursLabel = `${hours} ${i18n.t("hodiny")}`; // 2-4 hodiny
                    } else {
                        hoursLabel = `${hours} ${i18n.t("hodin")}`; // 5 or more hours (hodin)
                    }
                }

                // Handle minutes based on the number (grammatically correct for minutes)
                let minutesLabel = "";
                if (minutes > 0) {
                    if (minutes === 1) {
                        minutesLabel = `${minutes} ${i18n.t("minuta")}`; // Singular minute (1 minuta)
                    } else if (minutes >= 2 && minutes <= 4) {
                        minutesLabel = `${minutes} ${i18n.t("minuty")}`; // 2-4 minuty
                    } else {
                        minutesLabel = `${minutes} ${i18n.t("minut")}`; // 5 or more minutes (minut)
                    }
                }

                const label = `${hoursLabel} ${minutesLabel}`.trim();
                if (label) {
                    waitingTimeOptions.push({ label, value: totalMinutes / 60 });
                }
            }
        }

        // Add 1 day (24 hours) option after the loop
        waitingTimeOptions.push({ label: i18n.t("1 day"), value: 24 });

        return (
            <Row gutter={20}>
                <Col xs={24} sm={5} style={{ lineHeight: "30px" }}>
                    {i18n.t("Čekání")}
                    <Popover overlayClassName="order-form__popover" placement="top" content={i18n.t("Doba pobytu")}>
                        <HelpButton />
                    </Popover>
                </Col>
                <Col xs={24} sm={19}>
                    <Form.Item style={{ marginBottom: 10 }}>
                        <Select
                            value={NewOrderStore.excursionPoints[index].waitingTime}
                            onChange={(value) => this.handleWaitingTimeChange(index, value)}
                            style={{ width: "100%" }}
                        >
                            {waitingTimeOptions.map((option) => (
                                <Option key={option.value} value={option.value}>
                                    {option.label}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        );
    }

    renderExcursionPoint(index, data, routeNumber) {
        const lastOne = index + 1 === NewOrderStore.excursionPoints.length;
        
        const Title = () => {
            let text;
            if (index === 0) {
                text = i18n.t("Nástupní místo");
            } else if (lastOne) {
                text = i18n.t("Místo ukončení");
            } else {
                text = i18n.t("Další zastávka");
            }
            return (
                <>
                    <EnvironmentOutlined style={{ marginRight: 8 }} />
                    {text}
                </>
            );
        };

        return (
            <div>
                {index === 0 || (lastOne && NewOrderStore.excursionPoints.length < 3) ? (
                    <PageActionHeader title={<Title />} className="headtitlecst" />
                ) : (
                    <PageActionHeader
                        className="headtitlecst"
                        title={<Title />}
                        extra={[
                            <Button
                                onClick={() => {
                                    NewOrderStore.excursionPoints.splice(index, 1);
                                    CarCategoryStore.loadExcursion(NewOrderStore.excursionPoints, NewOrderStore.passengersCount);
                                }}
                                key={1}
                                type="text"
                                size="small"
                                style={{ margin: 5, border: "1px solid #005093" }}
                            >
                                <MinusCircleOutlined style={{ color: "#d91028" }} /> {i18n.t("Smazat cestu")}
                            </Button>,
                        ]}
                    />
                )}

                {this.renderInput(index, routeNumber)}

                {index !== 0 && index !== NewOrderStore.excursionPoints.length - 1 && (
                    <div style={{ paddingTop: 10 }}>{this.renderWaitingTime(index)}</div>
                )}

                {this.addNewPoint(index)}
            </div>
        );
    }

    renderInput(index) {
        let label = "";
        if (index + 1 === NewOrderStore.excursionPoints.length) {
            label = i18n.t("Do");
        } else if (index === 0) {
            label = i18n.t("Z");
        } else {
            label = i18n.t("Přes");
        }

        const placeHolder = i18n.t("Zadejte letiště, hotel, adresu...");

        const nos = NewOrderStore;
        const isError = nos.error && !nos.excursionPoints[index].place;
        const validation = isError ? "error" : null;

        let help = null;
        if (isError) {
            if (index === 0) {
                help = i18n.t("Prosím vyplňte místo z");
            } else if (index === nos.excursionPoints.length - 1) {
                help = i18n.t("Prosím vyplňte místo do");
            } else {
                help = i18n.t("Prosím vyplňte místo přes");
            }
        }

        return (
            <Row gutter={20}>
                <Col className="excursionViaLabel" xs={24} sm={5} style={{ lineHeight: "40px" }}>
                    {i18n.t(label)}
                </Col>
                <Col xs={24} sm={19}>
                    <Form.Item validateStatus={validation} help={help}>
                        <AutoCompleteLocation
                            placeholder={placeHolder}
                            id={`place-${index}`}
                            isStart={NewOrderStore.excursionPoints.length - 1 !== index}
                            onSearch={(phrase) => {
                                NewOrderStore.startValue = phrase;
                            }}
                            onChange={(value) => {
                                NewOrderStore.excursionPoints[index].place = value;
                                this.loadLocationInfo(index, value);
                                CarCategoryStore.loadExcursion(NewOrderStore.excursionPoints, NewOrderStore.passengersCount);
                            }}
                            onSelect={(value) => {
                                NewOrderStore.excursionPoints[index].place = value;
                                this.loadLocationInfo(index, value);
                                CarCategoryStore.loadExcursion(NewOrderStore.excursionPoints, NewOrderStore.passengersCount);
                            }}
                            defaultValue={NewOrderStore.excursionPoints[index].place}
                            style={{ width: "100%" }}
                            dropdownMatchSelectWidth={false}
                            dropdownStyle={{ width: 300 }}
                        />
                    </Form.Item>
                </Col>
            </Row>
        );
    }

    render() {
        const { excursionPoints } = NewOrderStore;
        const { routeNumber } = this.props;

        return (
            <div>
                <h2>
                   {i18n.t("Trasa")}
                </h2>
                <Card className="order-form__card custom-card">
                    {excursionPoints.map((excursionPoint, index) => (
                        <div key={index} /* eslint-disable-line react/no-array-index-key */>
                            {this.renderExcursionPoint(index, excursionPoint, routeNumber)}
                        </div>
                    ))}

                    <span className="order-form__stats">
                        <RenderStats />
                    </span>
                </Card>
            </div>
        );
    }
}

export default observer(RenderExcursion);
