import React, { Component } from "react";
import { Card, Row, Col, Popover, Form } from "antd";
import PropTypes from "prop-types";
import { makeObservable, action } from "mobx";
import { observer } from "mobx-react";
import i18n from "../../../../../core/i18n";
import HelpButton from "../../../../HelpButton";
import NewOrderStore from "../../../../../stores/Order/NewOrderStore";
import RenderStats from "./RenderStats";
import CarCategoryStore from "../../../../../stores/CarCategory/CarCategoryStore";
import AutoCompleteLocationStart from "./renderSimple/AutoCompleteLocationStart";
import AutoCompleteLocationFinish from "./renderSimple/AutoCompleteLocationFinish";
import CurrentLocation from "../../../../map/CurrentLocation";
import AutoCompleteLocationStore from "../../../../../stores/AutoCompleteLocation/AutoCompleteLocationStore";

class RenderSimple extends Component {
    static propTypes = {
        routeNumber: PropTypes.number.isRequired,
        mode: PropTypes.string,
    };

    static defaultProps = {
        mode: null,
    };

    constructor(props) {
        super(props);
        makeObservable(this, {
            onSearchStartHandle: action,
            onSelectStartHandle: action,
        });
    }

    // Action to handle search start
    onSearchStartHandle = (phrase) => {
        // eslint-disable-line class-methods-use-this
        NewOrderStore.start = null;
        NewOrderStore.startValue = phrase;
        CarCategoryStore.routeDistance = null;
        // NewOrderStore.calculatePrice();
    };

    // Action to handle select start
    onSelectStartHandle = (value) => {
        // eslint-disable-line class-methods-use-this
        const { routeNumber } = this.props;
        NewOrderStore.startValue = value;
        NewOrderStore.onSelectRoutePoint(value, routeNumber, "start");
        // NewOrderStore.calculatePrice();
    };

    render() {
        const { routeNumber, mode } = this.props;

        const isStarterrorMsg = () => {
            const ch = NewOrderStore;
            return ch.isstartRed;
        };

        const isfinsherrorMsg = () => {
            const ch = NewOrderStore;
            return ch.isFinishRed;
        };

        const nos = NewOrderStore;
        const isStartError = nos.error && !nos.start;
        const isFinishError = nos.error && !nos.finish;
        const isStartSameAsFinishError = nos.error && nos.start === nos.finish;
        const checkisf1Address = nos.isf1Address;

        const generalTexts = {
            fromLabel: i18n.t("Z"),
            fromPlaceHolder: i18n.t("Zadejte letiště, hotel, adresu..."),
            toLabel: i18n.t("Do"),
            toPlaceHolder: i18n.t("Zadejte letiště, hotel, adresu..."),
            fromHelp: i18n.t("Místo z – odkud – např. adresa, jméno hotelu nebo název letiště"),
            toHelp: i18n.t("Místo do – kam – např. adresa, jméno hotelu nebo název letiště"),
            fromInputValidation: isStartError || isStartSameAsFinishError || checkisf1Address ? "error" : null,
            fromInputHelp: () => {
                if (isStartError) return i18n.t("Prosím vyplňte místo z");
                if (isStartSameAsFinishError) return i18n.t("Prosím opravte informaci o trase");
                return null;
            },
            toInputValidation: isFinishError || isStartSameAsFinishError ? "error" : null,
            toInputHelp: () => {
                if (isFinishError) return i18n.t("Prosím vyplňte místo do");
                if (isStartSameAsFinishError) return i18n.t("Prosím opravte informaci o trase");
                return null;
            },
        };

        const hourRentalTexts = {
            fromLabel: i18n.t("Z"),
            fromPlaceHolder: i18n.t("Místo přistavení"),
            toLabel: i18n.t("Do"),
            toPlaceHolder: i18n.t("Místo ukončení"),
            fromHelp: i18n.t("Místo vyzvednutí vozidla"),
            toHelp: i18n.t("Místo ukončení pronájmu, nemusí se uvádět"),
            fromInputValidation: isStartError ? "error" : null,
            fromInputHelp: () => {
                if (isStartError) return i18n.t("Prosím vyberte místo přistavení");
                return null;
            },
            toInputValidation: isFinishError ? "error" : null,
            toInputHelp: () => {
                if (isFinishError) return i18n.t("Prosím vyplňte místo do");
                //if (isStartSameAsFinishError) return i18n.t("Prosím opravte informaci o trase");
                return null;
            },
        };

        const texts = mode === "hour-rental" ? hourRentalTexts : generalTexts;

        return (
            <span>
                <h2>
                   {i18n.t("Trasa")}
                </h2>
                <Card className="order-form__card custom-card">
                    <Row gutter={20} style={{ marginBottom: 12 }}>
                        <Col xs={24} sm={5} style={{ lineHeight: "40px" }}>
                            <span style={{ fontWeight: 500 }}> {texts.fromLabel}</span>
                            <Popover overlayClassName="order-form__popover" placement="top" content={texts.fromHelp}>
                                <HelpButton />
                            </Popover>
                        </Col>
                        <Col xs={24} sm={19}>
                            <Form.Item validateStatus={texts.fromInputValidation} help={texts.fromInputHelp()}>
                                <AutoCompleteLocationStart
                                    routeNumber={routeNumber}
                                    placeHolder={texts.fromPlaceHolder}
                                    onSearch={this.onSearchStartHandle}
                                    onSelect={this.onSelectStartHandle}
                                />
                                {isStarterrorMsg() && <span style={{ color: "red" }}>{i18n.t("Neúplná adresa")}</span>}
                                {AutoCompleteLocationStore.startcurrentLocation && <CurrentLocation />}
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={20}>
                        <Col xs={24} sm={5} style={{ lineHeight: "40px" }}>
                            <span style={{ fontWeight: 500 }}>{texts.toLabel}</span>
                            <Popover overlayClassName="order-form__popover" placement="top" content={texts.toHelp}>
                                <HelpButton />
                            </Popover>
                        </Col>
                        <Col xs={24} sm={19}>
                            <Form.Item validateStatus={texts.toInputValidation} help={texts.toInputHelp()}>
                                <AutoCompleteLocationFinish
                                    routeNumber={routeNumber}
                                    placeHolder={texts.toPlaceHolder}
                                    onSearch={this.onSearchStartHandle}
                                    onSelect={this.onSelectStartHandle}
                                />
                                {isfinsherrorMsg() && <span style={{ color: "red" }}>{i18n.t("Neúplná adresa")}</span>}
                                {AutoCompleteLocationStore.finishcurrentLocation && <CurrentLocation />}
                            </Form.Item>
                        </Col>
                    </Row>
                    <span className="order-form__stats">
                        <RenderStats />
                    </span>
                </Card>
            </span>
        );
    }
}

export default observer(RenderSimple);
