import React, { Component } from "react";
import { Popover, Button, Card, Divider, Row, Col } from "antd";
import { MinusOutlined, PlusOutlined, WarningOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import i18n from "../../../../../core/i18n";
import HelpButton from "../../../../HelpButton";
import NewOrderStore from "../../../../../stores/Order/NewOrderStore";

class ChildrenPart extends Component {
    static propTypes = {
        form: PropTypes.objectOf(PropTypes.any).isRequired,
        routeNumber: PropTypes.number.isRequired,
        formValues: PropTypes.objectOf(PropTypes.any).isRequired,
        formItemLayout: PropTypes.objectOf(PropTypes.any).isRequired,
    };

    toggleChildrenCard = () => {
        const { chooseChild } = NewOrderStore;
        chooseChild[NewOrderStore.getRouteWay()] = !chooseChild[NewOrderStore.getRouteWay()];
    };

    render() {
        const { form, routeNumber, formValues, formItemLayout } = this.props;
        const chooseChild = NewOrderStore.chooseChild[NewOrderStore.getRouteWay()];
        let isBabySeat = false;
        if (NewOrderStore && NewOrderStore.car && NewOrderStore.car.isNoBabySeat) {
            isBabySeat = true;
        } else {
            isBabySeat = false;
        }

        return (
          <div className="order-form-notes FlipDiv">
                <h2 className="order-form-notes-title">
                    {i18n.t("Dítě")}
                    <Popover
                        overlayClassName="order-form__popover"
                        placement="top"
                        content={i18n.t("Pokud s Vámi cestuje dítě, zvolte patřičnou dětskou autosedačku (podsedáky a dětské autosedačky)")}
                    >
                        <HelpButton />
                    </Popover>
                    <span className="order-form__extras-button" style={{ marginLeft: 10 }}>
                    <Button
                      type="ghost"
                      style={{
                        backgroundColor: "#ebe7e7",
                        fontSize: 14,
                        border: "1px solid rgba(0, 0, 0, 0.25)",
                        
                      }}
                      onClick={this.toggleChildrenCard}
                    >
                      {chooseChild ? (
                        <span>
                          <MinusOutlined style={{ marginInlineEnd: 6 }} />
                          <span className="extra-label">{i18n.t("Skrýt")}</span>
                        </span>
                      ) : (
                        <span>
                          <PlusOutlined style={{ marginInlineEnd: 6 }} />
                          <span className="extra-label">{i18n.t("Přidat")}</span>
                        </span>
                      )}
                    </Button>
                  </span>
                </h2>

                {chooseChild ? (
                    <span>
                        {isBabySeat ? (
                            <p>
                                <span className="mr-10">
                                    <WarningOutlined />
                                </span>
                                {i18n.t("For selected vehicles, we do not provide child seats.")}
                            </p>
                        ) : (
                            <Row gutter={40}>
                                <Col xs={24} md={20}>
                                    {form.render(`routes[${routeNumber}][kids1]`, {}, formItemLayout)}
                                    {form.render(`routes[${routeNumber}][kids2]`, {}, formItemLayout)}
                                    {form.render(`routes[${routeNumber}][kids3]`, {}, formItemLayout)}
                                </Col>
                            </Row>
                        )}
                    <Divider />
                    </span>
                ) : (
                    null
                )}
            </div>
        );
    }
}

export default observer(ChildrenPart);
