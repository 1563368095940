import React from "react";
import { Button, Col, Row, Empty, Typography } from "antd";
import { observer } from "mobx-react";
import NewOrderStore from "../../../../../../stores/Order/NewOrderStore";
import i18n from "../../../../../../core/i18n";
import CarCategoryStore from "../../../../../../stores/CarCategory/CarCategoryStore";
import CurrencyStore from "../../../../../../stores/Currency/CurrencyStore";
import getAmountInForeignCurrency from "../../../../../../utils/newOrderStore/getAmountInForeignCurrency";
import CustomCarSelectList from "../../CustomCarSelectList";
import getTotalPrice from "../../../../../../utils/newOrderStore/getTotalPrice";

const { Text } = Typography;

export const CarsListCustomCars = observer(() => {
    const { totalPrice } = getTotalPrice();

    const cardStyle = {
        border: "1px solid",
        borderColor:
            NewOrderStore.error &&
            !NewOrderStore.selectedCars[NewOrderStore.getRouteWay()].length &&
            !NewOrderStore.isLoading
                ? "#f5222d"
                : "#f0f0f0",
        borderRadius: "4px",
        padding: "16px",
        marginBottom: "16px",
    };

    const titleSection = (
        <Row justify="space-between" align="middle" style={{ marginBottom: "16px" }}>
            <Col>
                <Text strong style={{ fontSize: "16px" }}>
                    {i18n.t("Vyberte vozidla")}
                </Text>
            </Col>
            <Col>
                <Text>
                    <span style={{ fontSize: "16px", marginInlineEnd: "8px" }}>{i18n.t("Cena vozidel celkem")}:</span>
                    <strong>
                        {CurrencyStore.selectedCurrency !== CurrencyStore.CZK &&
                        CurrencyStore.currencies &&
                        getAmountInForeignCurrency(totalPrice, CurrencyStore.selectedCurrency) ? (
                            <>
                                <span>{getAmountInForeignCurrency(totalPrice, CurrencyStore.selectedCurrency)}</span>
                                <span style={{ color: "#808080", fontSize: "12px" }}>
                                    {" / "}
                                    {totalPrice} {CurrencyStore.CZK}
                                </span>
                            </>
                        ) : (
                            <span>
                                {totalPrice} {CurrencyStore.CZK}
                            </span>
                        )}
                    </strong>
                </Text>
            </Col>
        </Row>
    );

    return (
        <React.Fragment>
            <div style={cardStyle}>
                {titleSection}
                <div className="custom-car__choose-list" style={{ marginBottom: "16px" }}>
                    <CustomCarSelectList />
                </div>
                {!NewOrderStore.error && !CarCategoryStore.customCarCategories.length && (
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={i18n.t("Pro zobrazení dostupných vozidel nejdříve vyberte místo z-do, datum a čas.")}
                    />
                )}
            </div>
            {NewOrderStore.error && !NewOrderStore.selectedCars[NewOrderStore.getRouteWay()].length && !NewOrderStore.isLoading && (
                <Text type="danger">{i18n.t("Vyberte vozidlo")}</Text>
            )}
            {CarCategoryStore.customCarCategories.length > 3 && (
                <Row justify="center" style={{ marginTop: 20 }}>
                    <Col>
                        {NewOrderStore.showCustomCarsCount < CarCategoryStore.customCarCategories.length && (
                            <Button
                                style={{ marginTop:5, marginBottom: 15 }}
                                type="primary"
                                onClick={() => {
                                    NewOrderStore.showCustomCarsCount += 3;
                                }}
                            >
                                {i18n.t("Zobrazit více")}
                            </Button>
                        )}
                        {NewOrderStore.showCustomCarsCount > 3 && (
                            <Button
                                style={{ margin: 5 }}
                                onClick={() => {
                                    NewOrderStore.showCustomCarsCount = 3;
                                }}
                            >
                                {i18n.t("Skrýt")}
                            </Button>
                        )}
                    </Col>
                </Row>
            )}
        </React.Fragment>
    );
});

CarsListCustomCars.propTypes = {};

CarsListCustomCars.defaultProps = {};
