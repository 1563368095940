import React from "react";
import { Button, Col, Row } from "antd";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import NewOrderStore from "../../../../../../stores/Order/NewOrderStore";
import i18n from "../../../../../../core/i18n";
import TopCars from "../../../../../order/TopCars";
import CarCategoryStore from "../../../../../../stores/CarCategory/CarCategoryStore";

export const CarsListTopCars = observer((props) => {

    let { carCategories } = CarCategoryStore;

    return (
        <React.Fragment>
            <TopCars carCategories={carCategories} selectedCar={props.selectedCar} onSelect={props.onSelect} />
            {carCategories.length > 4 && (
                <Row type="flex" justify="center" >
                    <Col>
                        {NewOrderStore.showAllTopCarsCount < CarCategoryStore.carCategories.length && (
                            <Button
                                style={{ marginTop:5, marginBottom: 15 }}
                                type="primary"
                                onClick={() => {
                                    NewOrderStore.showAllTopCarsCount += 4;
                                }}
                            >
                                {i18n.t("Zobrazit více")}
                            </Button>
                        )}
                        {NewOrderStore.showAllTopCarsCount > 4 && (
                            <Button
                                style={{ margin: 5 }}
                                onClick={() => {
                                    NewOrderStore.showAllTopCarsCount = 4;
                                }}
                            >
                                {i18n.t("Skrýt")}
                            </Button>
                        )}
                    </Col>
                </Row>
            )}
        </React.Fragment>
    );
});

CarsListTopCars.propTypes = {
    onSelect: PropTypes.func.isRequired,
    selectedCar: PropTypes.string,
};

CarsListTopCars.defaultProps = {
    selectedCar: null,
};
